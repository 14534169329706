<div class="container">

    <h1>Register New Organisation</h1>

    <form [formGroup]="mainForm">
        <div fxLayout="row wrap" fxLayoutGap="8px grid">
            <div fxFlex="75%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Organisation Name</mat-label>
                    <input matInput type="text" formControlName="name" maxlength="128">
                    <mat-hint>The name of the organisation (usually your company's legal name)</mat-hint>
                </mat-form-field>
            </div>
            <div fxFlex="25%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Short Code</mat-label>
                    <input matInput type="text" formControlName="shortCode" minlength="3" maxlength="12">
                    <mat-hint>A unique identifier between 3 and 12 letters long</mat-hint>
                </mat-form-field>
            </div>
            <div fxFlex="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows="5" type="text" formControlName="description"></textarea>
                    <mat-hint>A brief description of the organisation</mat-hint>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div style="margin-top: 32px">
        <app-action-group>
            <app-action action="create" [disabled]="!mainForm.valid" (execute)="registerOrg()" label="REGISTER ORGANISATION"></app-action>
            <app-action action="revert" [routerLink]="'/home'" label="CANCEL"></app-action>
        </app-action-group>
    </div>

</div>