import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateUserAccountGQL, CreateUserAccountInput } from 'src/generated/graphql';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  signInForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl("", [Validators.email, Validators.required]),
    password: new UntypedFormControl("", [Validators.required])
  });

  registerForm = new UntypedFormGroup({
    emailAddress: new UntypedFormControl("", [Validators.email, Validators.required]),
    firstName: new UntypedFormControl("", [Validators.required]),
    lastName: new UntypedFormControl("", [Validators.required]),
    password: new UntypedFormControl("", [Validators.required, Validators.minLength(8)])
  });

  constructor(
    private auth: AngularFireAuth, 
    private userService: UserService,
    private createUserAccountGQL: CreateUserAccountGQL,
    private snackBar: MatSnackBar,
    router: Router
  ) {
    this.auth.currentUser.then(user => {
      if (user) {
        router.navigate(["/home"]);
      }
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.userService.signInWithEmailAndPassword(this.signInForm.value.emailAddress, this.signInForm.value.password).subscribe(user => {
      if (user) {
        console.log(`User signed in ${user.displayName}`);
      } else {
        console.log("User could not be signed in");
      }
    });
  }

  onRegister(): void {
    console.log("Registering");

    const userReg: CreateUserAccountInput = {
      emailAddress: this.registerForm.value.emailAddress,
      password: this.registerForm.value.password,
      firstName: this.registerForm.value.firstName,
      lastName: this.registerForm.value.lastName
    };

    this.createUserAccountGQL.mutate({ userRegistration: userReg }).subscribe(resp => {
      if (resp.errors) {
        console.log(resp.errors);
        this.snackBar.open(`Error: ${resp.errors[0].message}`, undefined, { duration: 3000, panelClass: ['snackbar-error'] });
      } else {
        if (resp.data?.createUserAccount) {
          this.auth.signInWithEmailAndPassword(this.registerForm.value.emailAddress, this.registerForm.value.password).then (x => {
            console.log("User login");
            console.log(x.user);
          }).catch(reason => {
            console.log("Auth failed");
            this.snackBar.open(`Error: ${reason}`, undefined, { duration: 3000, panelClass: ['snackbar-error'] });
          });
        }
      }
    });
  }

}
