<div class="wrapper">

    <div class="card-container">

        <mat-card class="mat-elevation-z8" style="border-radius: 20px">

            <mat-card-content class="login-content">

                <div style="padding: 16px 0 8px 0; text-align: center;">
                    <img src="/assets/img/logo/stevie_logo_black.png" style="height: 48px">
                </div>

                <mat-tab-group mat-align-tabs="center" #loginTabs animationDuration="0ms">

                    <mat-tab label="LOGIN">
                        <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
                            <div>
                                <div style="margin-top: 28px;">
                                    <mat-form-field style="width: 100%" appearance="outline">
                                        <mat-label>Email Address</mat-label>
                                        <input matInput type="email" required formControlName="emailAddress">
                                    </mat-form-field>
                                </div>
                                <div style="flex: 1">
                                    <mat-form-field style="width: 100%" appearance="outline">
                                        <mat-label>Password</mat-label>
                                        <input matInput type="password" required formControlName="password">
                                    </mat-form-field>
                                </div>
                                <div class="login-spacer" style="flex: 1">
                                    <app-action-group>
                                        <app-action action="default" label="SIGN IN" [disabled]="!signInForm.valid"></app-action>
                                        <app-action action="default" [muted]="true" label="FORGOTTEN PASSWORD"></app-action>
                                    </app-action-group>
                                </div>
                            </div>
                        </form>
                    </mat-tab>

                    <mat-tab label="REGISTER">
                        <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
                            <div style="padding-top: 28px">
                                <mat-form-field style="width: 100%" appearance="outline">
                                    <mat-label>Email Address</mat-label>
                                    <input matInput type="email" required formControlName="emailAddress">
                                </mat-form-field>
                                <mat-form-field style="width: 100%" appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input matInput type="text" required formControlName="firstName">
                                </mat-form-field>
                                <mat-form-field style="width: 100%" appearance="outline">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput type="text" required formControlName="lastName">
                                </mat-form-field>
                                <mat-form-field style="width: 100%" appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input matInput type="password" required formControlName="password">
                                </mat-form-field>
                                <div class="login-spacer" style="flex: 1">
                                    <app-action [disabled]="!registerForm.valid" label="REGISTER"></app-action>
                                </div>
                            </div>
                        </form>
                    </mat-tab>

                </mat-tab-group>

            </mat-card-content>

        </mat-card>


    </div>

</div>