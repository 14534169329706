import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CreateOrganisationGQL } from 'src/generated/graphql';

@Component({
  selector: 'app-organisation-registration',
  templateUrl: './organisation-registration.component.html',
  styleUrls: ['./organisation-registration.component.css'],
})
export class OrganisationRegistrationComponent implements OnInit {

  constructor(
    private createOrganisationGQL: CreateOrganisationGQL,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  mainForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.maxLength(128)]),
    description: new UntypedFormControl("", [Validators.required, Validators.maxLength(2000)]),
    shortCode: new UntypedFormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(12), Validators.pattern('^[a-zA-Z]*$')])
  });

  ngOnInit(): void {
    const shortCodeControl = this.mainForm.get('shortCode');
    if (shortCodeControl) {
      shortCodeControl.valueChanges.subscribe({
        next: () => {
          shortCodeControl.patchValue(shortCodeControl.value.toUpperCase().replace(/[^A-Z]/g,''), { emitEvent: false });
        }
      });
    }
  }

  registerOrg(): void {
    if (!this.mainForm.valid) {
      return;
    }

    this.createOrganisationGQL.mutate({ 
      organisation: { 
        name: this.mainForm.get("name")?.value,
        shortCode: this.mainForm.get("shortCode")?.value,
        description: this.mainForm.get("description")?.value
      } 
    }).subscribe(resp => {
      if (resp.errors) {
        this.snackBar.open(`Error: ${resp.errors[0].message}`, undefined, { duration: 3000, panelClass: ['snackbar-error'] });
      } else {
        if (resp.data?.createOrganisation?.shortCode) {
          this.snackBar.open("Organisation Registered", undefined, { duration: 3000 });
          this.router.navigate(["/org", resp.data.createOrganisation.shortCode, "dashboard"]);
        } else {
          this.snackBar.open(`Error: Could not identify created organisation from server response`, undefined, { duration: 3000, panelClass: ['snackbar-error'] });
        }
      }
    });

  }

}
