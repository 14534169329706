import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { MarkNotificationReadGQL, Notification } from 'src/generated/graphql';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  notifications!: Notification[];

  constructor(
    private notificationService: NotificationService
  ) { 
    this.loadNotifications();
  }
 
  ngOnInit(): void {
  }

  loadNotifications(): void {
    this.notifications = this.notificationService.getNotifications();
  }

  markRead(notification: Notification) {
    this.notificationService.markRead(notification);
    this.loadNotifications();
  }

}
