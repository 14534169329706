import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Invitation, GetUserInvitationsGQL } from 'src/generated/graphql';
import { Resolved } from '../models/resolved';

@Injectable({
  providedIn: 'root'
})
export class UserInvitationsResolver implements Resolve<Resolved<Invitation[]>> {

  constructor(
    private router: Router,
    private getUserInvitationelsGQL: GetUserInvitationsGQL
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Resolved<Invitation[]>> {

    return this.getUserInvitationelsGQL.fetch({}, { fetchPolicy: "no-cache" }).pipe(
      map(resp => {
        if (!resp.data.userInvitations) {
          this.router.navigate(["/home"]);
          return { object: null, error: "Channel data missing from GQL response" } as Resolved<Invitation[]>; 
        }
        return { object: resp.data.userInvitations } as Resolved<Invitation[]>; 
      }),
      catchError(error => {
        this.router.navigate(["/home"]);
        return of({ object: null, error } as Resolved<Invitation[]>);
      })
    );
  }
}
