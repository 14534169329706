import { BreakpointObserver } from '@angular/cdk/layout';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import {NavigationEnd, Router, Event, RouterEvent, ActivatedRoute, NavigationStart} from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ChatService } from 'src/app/services/chat.service';
import { NetworkService } from 'src/app/services/network.service';
import { NotificationService } from 'src/app/services/notification.service';
import { PortalBridgeService } from 'src/app/services/portal-bridge.service';
import { UserService } from 'src/app/services/user.service';
import { ChatMessage, GetCurrentUserGQL, Notification, OnChatMessageGQL, OnNotificationGQL, UserAccount } from 'src/generated/graphql';
import {UserInterfaceService} from "../../services/user-interface.service";

@Component({
  selector: 'app-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.scss']
})
export class StandardLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild("navtoggle", {read: ElementRef}) navtoggle!: ElementRef;

  whitePixel = "data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";
  thumbnail?: string = undefined;

  portalSidenav?: TemplatePortal;
  portalOrganisation?: TemplatePortal;

  inOrgContext = false;

  user!: UserAccount | undefined;
  userAvatar = "";
  inFlightRequests = 0;

  inflight$!: Subscription;
  notificationItem$?: Subscription;
  chatMessage$?: Subscription;
  unreadNotificationCount$!: Subscription;
  token$!: Subscription;
  avatar$!: Subscription;

  unreadNotifications = 0;
  userAccount$: Subscription;

  constructor(
    router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private chatService: ChatService,
    private notificationService: NotificationService,
    private userInterfaceService: UserInterfaceService,
    private observer: BreakpointObserver,
    private portalBridge: PortalBridgeService,
    private getCurrentUserGQL: GetCurrentUserGQL,
    private networkService: NetworkService,
    private onNotificationGQL: OnNotificationGQL,
    private onChatMessageGQL: OnChatMessageGQL,
    private snackBar: MatSnackBar
  ) {

    this.userInterfaceService.getSidebarCollapse().subscribe(collapse => {
      if (!this.sidenav) return;
      if (collapse) {
        this.sidenav.close();
      } else {
        this.sidenav.open();
      }
    });

    this.portalBridge.portalSidenav$.subscribe(tp => {
      this.portalSidenav = tp;
    });
    this.portalBridge.portalorganisation$.subscribe(tp => {
      this.portalOrganisation = tp;
    });

    this.inflight$ = this.networkService.inFlight.pipe(debounceTime(200)).subscribe(reqCount => {
      this.inFlightRequests = reqCount;
    });

    this.userAccount$ = this.userService.getUserAccount().subscribe(user => {
      if (user) {
        this.user = user;
        this.userAvatar = this.user.userAvatar96 || "";

        if (this.user.userAvatar96) {
          this.thumbnail = this.user.userAvatar96;
        } else {
          this.thumbnail = undefined;
        }
      }
    });

    router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof NavigationEnd || e instanceof NavigationStart)
    ).subscribe(ev => {

      if (ev instanceof NavigationStart) {
        this.userInterfaceService.setSidebarCollapse(false);
      }

      if (ev instanceof NavigationEnd) {
        this.inOrgContext = ev.url.startsWith("/org");

        // If mobile layout, close the sidenav when navigation completes
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
          this.navtoggle.nativeElement.blur();
        }

      }
    });
  }

  ngOnInit() {

    /*
    this.getCurrentUserGQL.fetch().subscribe(resp => {
      this.user = resp.data.currentUser as UserAccount;
      if (this.user.userAvatar96) {
        this.userAvatar = this.user.userAvatar96;
      }
    });
    */

    this.token$ = this.userService.getToken().subscribe(token => {
      if (token) {
        this.notificationItem$ = this.onNotificationGQL.subscribe({ idtoken: token }).subscribe(resp => {
          if (resp.data?.onNotification) {
            this.notificationService.onNotificationReceived(resp.data.onNotification as Notification);
            if (!resp.data.onNotification.isRead) {
              this.snackBar.open(resp.data?.onNotification?.subject, undefined, { duration: 5000 });
            }
          }
        });
        this.chatMessage$ = this.onChatMessageGQL.subscribe({ idtoken: token }).subscribe(resp => {
          if (resp.data?.onChatMessage) {
            this.chatService.onMessageReceived(resp.data.onChatMessage as ChatMessage);
          }
        });
        this.notificationService.loadNotifications();
      }
    });

    this.avatar$ = this.userService.getAvatar().subscribe(avatar => {
      this.userAvatar = avatar;
    });

    this.unreadNotificationCount$ = this.notificationService.unreadCount.subscribe(unreadCount => {
      this.unreadNotifications = unreadCount;
    });
  }

  ngOnDestroy() {
    this.inflight$.unsubscribe();
    this.token$.unsubscribe();
    this.unreadNotificationCount$?.unsubscribe();
    this.notificationItem$?.unsubscribe();
    this.chatMessage$?.unsubscribe();
    this.avatar$.unsubscribe();
    this.userAccount$.unsubscribe();
  }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        setTimeout(() => {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        });
      } else {
        setTimeout(() => {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        });
      }
    });
  }

  logout(): void {
    this.userService.signOut();
    this.userAvatar = "";
  }
}
