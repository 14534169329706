<router-outlet></router-outlet>

<ng-template #organisationPortalContent>
    <a mat-button [matMenuTriggerFor]="orgMenu">{{organisation.name}} <mat-icon>expand_more</mat-icon></a>
    <mat-menu #orgMenu="matMenu" xPosition="before">
        <a *ngFor="let mbr of user.organisationMemberships" mat-menu-item [routerLink]="['/org',mbr?.organisation?.shortCode]"><mat-icon>store</mat-icon> {{mbr?.organisation?.name}}</a>
    </mat-menu>
</ng-template>

<ng-template #sidenavPortalContent>

    <!--<h3 matSubheader>{{organisation.name}}</h3>-->


  <cdk-accordion>

    <a mat-list-item routerLinkActive="active" [routerLink]="['dashboard']">
      <fa-icon icon="home" size="lg" [fixedWidth]="true"></fa-icon>
      Home
    </a>

    <app-sitenav-group icon="magic" text="Create" [expanded]="true" *ngIf="viewRoles.includes(organisationService.roleProduct) || userIsAdmin" [active]="productsActive1.isActive || productsActive2.isActive || productsActive3.isActive">
        <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','products','items']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #productsActive1="routerLinkActive">
          <fa-icon icon="box" size="lg"></fa-icon>
          Product Home
        </a>
        <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','products','categories']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #productsActive2="routerLinkActive">
          <fa-icon icon="book" size="lg"></fa-icon>
          Categories
        </a>
        <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','products','product-groups']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #productsActive3="routerLinkActive">
          <fa-icon icon="boxes" size="lg"></fa-icon>
          Groups
        </a>
    </app-sitenav-group>

    <app-sitenav-group icon="blender" text="Cook" *ngIf="viewRoles.includes(organisationService.roleRecipe) || userIsAdmin" [active]="recipesActive1.isActive || recipesActive2.isActive || recipesActive3.isActive">
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','recipes','items']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #recipesActive1="routerLinkActive">
        <fa-icon icon="file" size="lg"></fa-icon>
        Recipes
      </a>
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','recipes','categories']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #recipesActive2="routerLinkActive">
        <fa-icon icon="book" size="lg"></fa-icon>
        Categories
      </a>
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','recipes','ingredients']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #recipesActive3="routerLinkActive">
        <fa-icon icon="pepper-hot" size="lg"></fa-icon>
        Ingredients
      </a>
    </app-sitenav-group>

    <app-sitenav-group icon="box-open" text="Pack" *ngIf="viewRoles.includes(organisationService.rolePackaging) || userIsAdmin" [active]="packActive1.isActive || packActive2.isActive || packActive3.isActive">
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','packaging','items']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #packActive1="routerLinkActive">
        <fa-icon icon="box" size="lg"></fa-icon>
        Packaging
      </a>
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','packaging','categories']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #packActive2="routerLinkActive">
        <fa-icon icon="book" size="lg"></fa-icon>
        Categories
      </a>
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','packaging','materials']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #packActive3="routerLinkActive">
        <fa-icon icon="tree" size="lg"></fa-icon>
        Materials
      </a>
    </app-sitenav-group>

    <app-sitenav-group icon="paint-brush" text="Design" *ngIf="viewRoles.includes(organisationService.roleArtwork) || userIsAdmin" [active]="artworkActive1.isActive || artworkActive2.isActive">
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','artwork','items']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #artworkActive1="routerLinkActive">
        <fa-icon icon="pencil-ruler" size="lg"></fa-icon>
        Artwork Projects
      </a>
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','artwork','categories']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #artworkActive2="routerLinkActive">
        <fa-icon icon="book" size="lg"></fa-icon>
        Categories
      </a>
    </app-sitenav-group>

    <app-sitenav-group icon="comment-dots" text="Collaborate" [active]="collabActive1.isActive || collabActive2.isActive || collabActive3.isActive">
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','collab','messages']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #collabActive1="routerLinkActive">
        <fa-icon icon="comments" size="lg"></fa-icon>
        Chat
      </a>
      <a [ngStyle]="{display: userIsAdmin ? '':'none'}" mat-list-item routerLinkActive="subactive" [routerLink]="['npd','collab','teams']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #collabActive2="routerLinkActive">
        <fa-icon icon="people-arrows" size="lg"></fa-icon>
        Teams
      </a>
      <a [ngStyle]="{display: userIsAdmin ? '':'none'}" mat-list-item routerLinkActive="subactive" [routerLink]="['npd','collab','team-roles']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #collabActive3="routerLinkActive">
        <fa-icon icon="user-tag" size="lg"></fa-icon>
        Team Roles
      </a>
    </app-sitenav-group>

    <a mat-list-item routerLinkActive="active" [routerLink]="['npd','reports']">
      <fa-icon icon="chart-pie" size="lg" [fixedWidth]="true"></fa-icon>
      Report
    </a>

    <app-sitenav-group icon="store" text="Organisation" *ngIf="userIsAdmin" [active]="orgActive1.isActive || orgActive2.isActive || orgActive3.isActive">
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','admin','settings']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #orgActive1="routerLinkActive">
        <mat-icon class="nav-icon">settings</mat-icon>
        Settings
      </a>
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','admin','users']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #orgActive2="routerLinkActive">
        <fa-icon icon="users" size="lg"></fa-icon>
        Users
      </a>
      <a mat-list-item routerLinkActive="subactive" [routerLink]="['npd','admin','custom-fields']" class="subitem" [routerLinkActiveOptions]="{exact: false}" #orgActive3="routerLinkActive">
        <fa-icon icon="database" size="lg"></fa-icon>
        Custom Fields
      </a>
    </app-sitenav-group>

  </cdk-accordion>

</ng-template>
