import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  authState: string = "INIT";

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    this.userService.getToken().subscribe(token => {
      if (token) {
        this.authState = "AUTH";
      } else {
        this.authState = "NOAUTH";
        this.router.navigate(["/login"]);
      }
    });
  }
}
