<div>

  <div (click)="toggle()" class="nav-item" [ngClass]="{groupactive: active}">
    <div style="flex: 1;">
      <fa-icon [icon]="icon" size="lg" [fixedWidth]="true"></fa-icon>
      <span class="group-text">{{text}}</span>
    </div>
    <div style="width: 24px; text-align: right">
      <span class="spinner" style="display: inline-block;" [@indicatorRotate]="getExpandedState()"><fa-icon
        icon="caret-right" size="lg"></fa-icon></span>
    </div>
  </div>

  <div class="accordion-panel" [class.expanded]="expandedChange | async" [@bodyExpansion]="getExpandedState()"
       style="overflow: hidden;">
    <div>
      <ng-content></ng-content>
    </div>
  </div>

</div>
