import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-action-group',
  templateUrl: './action-group.component.html',
  styleUrls: ['./action-group.component.css']
})
export class ActionGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
