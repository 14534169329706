import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CategoryService } from 'src/app/services/category.service';
import { OrganisationService } from 'src/app/services/organisation.service';
import { PortalBridgeService } from 'src/app/services/portal-bridge.service';
import { UserService } from 'src/app/services/user.service';
import { AccessLevel, Category, Organisation, RoleLevel, UserAccount } from 'src/generated/graphql';

@Component({
  selector: 'app-organisation',
  templateUrl: './organisation.component.html',
  styleUrls: ['./organisation.component.css']
})
export class OrganisationComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("sidenavPortalContent")
  sidenavPortalContent!: TemplateRef<unknown>;

  @ViewChild("organisationPortalContent")
  organisationPortalContent!: TemplateRef<unknown>;

  templatePortal!: TemplatePortal<any>;
  templatePortalOrganisation!: TemplatePortal<any>;

  public organisation: Organisation;

  user: UserAccount;
  resolvedData$: Subscription;

  viewRoles = [RoleLevel.View, RoleLevel.Manage, RoleLevel.Admin];
  manageRoles = [RoleLevel.Manage, RoleLevel.Admin];
  adminRoles = [RoleLevel.Admin];

  userIsAdmin = false;

  constructor(
    private portalBridge: PortalBridgeService, 
    private viewContainerRef: ViewContainerRef,
    public organisationService: OrganisationService,
    userService: UserService,
    categoryService: CategoryService,
    route: ActivatedRoute
  ) {
    this.organisation = route.snapshot.data.organisation.object as Organisation;
    categoryService.setCategories(route.snapshot.data.categories.object as Category[]);

    this.resolvedData$ = route.data.subscribe(data => {
      if (data?.organisation?.object) {
        this.organisation = data.organisation.object as Organisation;
        console.log(`Organisation context changed to ${this.organisation.name}`);
        this.userIsAdmin = organisationService.accessLevel == AccessLevel.Administrator;
        localStorage.setItem("MRU_Org", this.organisation.shortCode);
      }
    })

    this.user = userService.user as UserAccount;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.templatePortal = new TemplatePortal(this.sidenavPortalContent, this.viewContainerRef);
    this.portalBridge.setPortalSidenav(this.templatePortal);

    this.templatePortalOrganisation = new TemplatePortal(this.organisationPortalContent, this.viewContainerRef);
    this.portalBridge.setPortalOrganisation(this.templatePortalOrganisation);
  }

  ngOnDestroy() {
    if (this.resolvedData$) this.resolvedData$.unsubscribe();
    if (this.templatePortal.isAttached) this.templatePortal.detach();
    if (this.templatePortalOrganisation.isAttached) this.templatePortalOrganisation.detach();
  }
}
