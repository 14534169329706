<mat-toolbar class="mat-elevation-z1">

    <button #navtoggle mat-icon-button (click)="sidenav.toggle()" aria-hidden="false" aria-label="Profile" style="margin-right: 6px">
        <mat-icon *ngIf="!sidenav.opened">menu</mat-icon>
        <mat-icon *ngIf="sidenav.opened">menu_open</mat-icon>
    </button>

    <span *ngIf="sidenav?.mode === 'side'" [routerLink]="'/'" class="app-name"><img src="../../../assets/img/logo/stevie_logo_black.png" style="height: 22px; position: relative; top: 4px;"></span>

    <span class="toolbar-spacer"></span>

    <ng-template [cdkPortalOutlet]="portalOrganisation"></ng-template>

    <button mat-icon-button class="toolbar-button" aria-label="User notifications">
        <mat-icon [matBadge]="unreadNotifications" [matBadgeHidden]="!unreadNotifications" matBadgeColor="accent" [routerLink]="'/notifications'">notifications</mat-icon>
    </button>

    <button mat-icon-button class="toolbar-button" aria-label="User account settings" [matMenuTriggerFor]="userMenu">
      <ngx-avatars [name]="user?.displayName || 'User'" [src]="thumbnail" [size]="34" class="profile-avatar"></ngx-avatars>
    </button>
    <mat-menu #userMenu="matMenu">
        <button mat-menu-item routerLink="/profile"><mat-icon>account_circle</mat-icon> User Profile</button>
        <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon> Sign Out</button>
    </mat-menu>


</mat-toolbar>

<mat-sidenav-container>

    <mat-sidenav #sidenav fixedInViewport="true" fixedTopGap="56" class="standard-layout-sidenav" [disableClose]="true">

        <mat-nav-list class="nav-list">

          <!--
            <div *ngIf="user" class="sidenav-title" [routerLink]="'/profile'">
                <div class="title-avatar">
                    <ngx-avatars *ngIf="!userAvatar" name={{user.displayName}} initialsSize="2" size="64"></ngx-avatars>
                    <ngx-avatars *ngIf="userAvatar" src={{userAvatar}} size="64"></ngx-avatars>
                </div>
                <div class="title-username">
                    {{user.displayName}}
                </div>
                <div class="title-email">
                    {{user.emailAddress}}
                </div>
            </div>
          -->

          <div style="height: 12px"></div>

            <ng-container *ngIf="!inOrgContext">
                <a mat-list-item routerLinkActive="active" routerLink="/home">
                    <fa-icon icon="home" size="lg" [fixedWidth]="true"></fa-icon>
                    Home
                </a>
                <a mat-list-item routerLinkActive="active" routerLink="/profile">
                    <fa-icon icon="user-circle" size="lg" [fixedWidth]="true"></fa-icon>
                    User Profile
                </a>
            </ng-container>

            <ng-template [cdkPortalOutlet]="portalSidenav"></ng-template>

        </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content>
        <div class="content">
            <ng-content></ng-content>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>

<div class="busy" *ngIf="inFlightRequests > 0">
    <!--<mat-spinner color="accent" diameter="32"></mat-spinner>-->
    <img src="../../../assets/img/loading/sparke_loading_red.gif" style="width: 32px">
    <div style="margin-left: 16px">Fetching Data...</div>
</div>
