import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NetworkService } from '../services/network.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable()
export class ApolloInterceptor implements HttpInterceptor {

  constructor(
    private userService: UserService, 
    private snackBar: MatSnackBar, 
    private networkService: NetworkService, 
    private analytics: AngularFireAnalytics) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.indexOf('/graphql') > -1) {
      this.networkService.beginNetworkRequest();
      return this.userService.getToken()
        .pipe(
          first(),
          switchMap(idToken => {
            const clone = req.clone({
              setHeaders: {
                Authorization: `Bearer ${idToken}`
              }
            });
            return next.handle(clone).pipe(
              map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  this.networkService.endNetworkRequest();
                  if (event.body.errors) {
                    console.log('error >>> ', event.body.errors);
                    this.snackBar.open(`Error: ${event.body.errors[0].message}` || "An error occurred while fetching data", "Close", { duration: 0, panelClass: ['snackbar-error'] });
                    this.analytics.logEvent("gql_error", { errors: event.body.errors });
                  }
                }
                return event;
              }),
              catchError((error: HttpErrorResponse) => {
                this.networkService.endNetworkRequest();
                let data = {};
                data = {
                  reason: error && error.error && error.error.reason ? error.error.reason : '',
                  status: error.status
                };
                let message = error.message;
                if (error.error && error.error.errors && error.error.errors[0].message) {
                  message = error.error.errors[0].message;
                }
                //this.errorDialogService.openDialog(data);
                this.snackBar.open(`Error: ${message}` || "An error occurred while communicating with the server", "Close", { duration: 0 });
                console.log("HTTP Error Intercepted", error);
                return throwError(error);
              }));
          }));
    };

    return next.handle(req);
  };
}