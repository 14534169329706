import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { GetCurrentUserGQL, Invitation, ReviewInvitationGQL, UpdateUserProfileGQL, UploadUserAvatarGQL, UserAccount } from 'src/generated/graphql';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  user!: UserAccount;
  userJson = "";

  invitations: Invitation[];

  mainForm = new UntypedFormGroup({
    firstName: new UntypedFormControl("", [Validators.required, Validators.maxLength(50)]),
    lastName: new UntypedFormControl("", [Validators.required, Validators.maxLength(50)]),
    displayName: new UntypedFormControl("", [Validators.required, Validators.maxLength(50)]),
    emailAddress: new UntypedFormControl({ value: "" ,disabled: true }, [Validators.required, Validators.maxLength(2000)]),
    emailConsentMarketing: new UntypedFormControl(null),
    emailConsentNotifications: new UntypedFormControl(null),
    emailConsentTasks: new UntypedFormControl(null),
  });

  constructor(
    private userService: UserService,
    private uploadUserAvatarGQL: UploadUserAvatarGQL,
    private reviewInvitationGQL: ReviewInvitationGQL,
    private getCurrentUserGQL: GetCurrentUserGQL,
    private updateUserProfileGQL: UpdateUserProfileGQL,
    route: ActivatedRoute
  ) { 
    this.user = userService.user as UserAccount;
    this.userJson = JSON.stringify(this.user, null, 2);
    this.invitations = route.snapshot.data.invitations.object as Invitation[];
  }

  ngOnInit(): void {
    this.revertChanges();
  }

  revertChanges() {
    this.mainForm.patchValue({
      displayName: this.user.displayName,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      emailAddress: this.user.emailAddress,
      emailConsentMarketing: this.user.emailConsentMarketing,
      emailConsentNotifications: this.user.emailConsentNotifications,
      emailConsentTasks: this.user.emailConsentTasks
    });
    this.mainForm.markAsPristine();
  }

  thumbnailChanged(imageData: string) {
    if (imageData) {
      this.uploadUserAvatarGQL.mutate({ imageData: imageData }).subscribe(resp => {
        if (!resp.errors && resp.data?.uploadUserAvatar) {
          this.userService.setAvatar(imageData);
        }
      }); 
    }
  }

  acceptInvitation(invitation: Invitation) {
    this.reviewInvitationGQL.mutate({ invitationId: invitation.id, accept: true }).subscribe(resp => {
      if (!resp.errors) {
        this.invitations = this.invitations.filter(x => x.id !== invitation.id);
        this.getCurrentUserGQL.fetch({}, { fetchPolicy: "no-cache" }).subscribe(resp => {
          if (resp.data.currentUser) {
            this.userService.user = resp.data.currentUser as UserAccount;
          }
        })
      }
    })
  }

  rejectInvitation(invitation: Invitation) {
    this.reviewInvitationGQL.mutate({ invitationId: invitation.id, accept: false }).subscribe(resp => {
      if (!resp.errors) {
        this.invitations = this.invitations.filter(x => x.id !== invitation.id);
      }
    })
  }

  saveProfile() {
    this.updateUserProfileGQL.mutate({ profile: {
      firstName: this.mainForm.get("firstName")?.value,
      lastName: this.mainForm.get("lastName")?.value,
      displayName: this.mainForm.get("displayName")?.value,
      emailConsentMarketing: this.mainForm.get("emailConsentMarketing")?.value,
      emailConsentNotifications: this.mainForm.get("emailConsentNotifications")?.value,
      emailConsentTasks: this.mainForm.get("emailConsentTasks")?.value
    }}).subscribe(resp => {
      if (!resp.errors && resp.data?.updateUserProfile) {
        this.userService.refresh();
        this.mainForm.markAsPristine();
        this.user = { ...this.user, displayName: resp.data.updateUserProfile.displayName };
      }
    });
  }

  undoProfileChanges() {    
    this.revertChanges();
  }
}
