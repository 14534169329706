import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AccessLevel, Category, ChatMessage, EntityType, GetAllCategoriesGQL, GetCategoriesGQL, Organisation, RoleLevel, UserAccount } from 'src/generated/graphql';
import { OrganisationService } from './organisation.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

    private _categories: Category[] = [];

    getCategories(entityType: EntityType): Category[] {
        return this._categories.filter(x => x.entityType === entityType);
    }

    setCategories(categories: Category[]) {
        this._categories = categories;
    }

    addCategory(category: Category) {
        this._categories = [...this._categories, category];
    }

    removeCategory(category: Category) {
        this._categories = this._categories.filter(x => x.id !== category.id);
    }

    updateCategory(category: Category) {
        this._categories = this._categories.map(x => {
            if (x.id !== category.id) {
                return x;
            } else {
                return {...category};
            }
        });
    }
}
