<ng-container [ngSwitch]="authState">

    <div *ngSwitchCase="'INIT'" style="text-align: center; padding-top: 120px;">
        Loading...
    </div>
    
    <ng-container *ngSwitchCase="'AUTH'">
        <app-standard-layout>
            <router-outlet></router-outlet>
        </app-standard-layout>
    </ng-container>

    <app-login-page *ngSwitchCase="'NOAUTH'">
    </app-login-page>  

</ng-container>
