import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';
import { AvatarUploadOptions } from 'src/app/models/avatar-upload-options';
import { CreateMaterialGQL, GetMaterialTypesGQL, MaterialType } from 'src/generated/graphql';

@Component({
  selector: 'app-avatar-upload-dialog',
  templateUrl: './avatar-upload-dialog.component.html',
  styleUrls: ['./avatar-upload-dialog.component.css']
})
export class AvatarUploadDialogComponent implements OnInit {

  @ViewChild("avatarCropper")
  avatarCropper!: ImageCropperComponent;

  showCropper = false;
  imageReady = false;

  imageSize!: number;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    private dialogRef: MatDialogRef<AvatarUploadDialogComponent>,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: AvatarUploadOptions
  ) {
    this.imageSize = data.size || 56;
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const img = this.avatarCropper.crop();
    if (img?.base64) {
      this.dialogRef.close(img.base64);
    }
  }

  onClear(): void {
    this.dialogRef.close("CLEAR");
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) {
      // show cropper
      this.ngZone.run(() => {
        this.showCropper = true;
      });
  }

  cropperReady() {
      // cropper ready
  }

  loadImageFailed() {
      // show message
  }
}
