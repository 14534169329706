import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetNotificationsGQL, Maybe } from 'src/generated/graphql';
import { Resolved } from '../models/resolved';
import { OrganisationService } from '../services/organisation.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsResolver implements Resolve<Resolved<Notification[]>> {

  constructor(
    private router: Router,
    private getNotificationsGQL: GetNotificationsGQL,
    private organisationService: OrganisationService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Resolved<Notification[]>> {

    return this.getNotificationsGQL.fetch().pipe(
      map(resp => {
        if (!resp.data.notifications) {
          this.router.navigate(["/home"]);
          return { object: null, error: "Notifications data missing from GQL response" } as Resolved<Notification[]>; 
        }
        const coms = (resp.data.notifications as Maybe<Notification>[]) as Notification[];
        return { object: coms } as Resolved<Notification[]>; 
      }),
      catchError(error => {
        this.router.navigate(["/home"]);
        return of({ object: null, error } as Resolved<Notification[]>);
      })
    );
  }
}
