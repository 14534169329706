import { Component, OnInit } from '@angular/core';
import { OrganisationService } from 'src/app/services/organisation.service';
import { ChartElement, GetOrganisationDashboardDataGQL, Organisation, OrganisationDashboardData } from 'src/generated/graphql';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleChartInterface, GoogleChartType } from 'ng2-google-charts';
import {UserInterfaceService} from "../../services/user-interface.service";

@Component({
  selector: 'app-organisation-dashboard',
  templateUrl: './organisation-dashboard.component.html',
  styleUrls: ['./organisation-dashboard.component.css']
})
export class OrganisationDashboardComponent implements OnInit {

  organisation!: Organisation;
  data: any;
  shortCode = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private getOrganisationDashboardDataGQL: GetOrganisationDashboardDataGQL,
    userInterfaceService: UserInterfaceService
  ) {

    userInterfaceService.setSidebarCollapse(true);

    this.route.data.subscribe(data => {
      if (data.organisation?.object) {
        this.organisation = this.route.snapshot.data.organisation.object as Organisation;
        this.shortCode = this.organisation.shortCode;
        this.getOrganisationDashboardDataGQL.fetch({ organisationId: this.organisation.id }, { fetchPolicy: "no-cache" }).subscribe(resp => {
          this.data = resp.data.organisationDashboardData;
          this.buildCharts();
        })
      }
    })
  }

  formatLabel(name: any) {
    let self: any = this;
    let data = self.series.filter((x: { name: any; }) => x.name == name);

    if(data.length > 0) {
      return `${data[0].name} ${data[0].value}`;
    } else {
      return name;
    }
  }

  ngOnInit(): void {
  }

  chooseModule(moduleId: string) {
    this.router.navigate(['org', this.organisation.shortCode, 'npd', moduleId]);
  }

  buildCategoryLegend(vals: ChartElement[]) {
    const liveCount = vals.find(x => x.name === "Live")?.value || 0;
    const onHoldCount = vals.find(x => x.name === "On Hold")?.value || 0;
    const completedCount = vals.find(x => x.name === "Completed")?.value || 0;
    const cancelledCount = vals.find(x => x.name === "Cancelled")?.value || 0;

    return `Live ${liveCount}, Completed ${completedCount}, On Hold ${onHoldCount}, Cancelled ${cancelledCount}`;
  }

  buildChart(data: ChartElement[]): any {
    return [
      [{ label: "Category", type: "string"}, { label: "Count", type: "number" }],
      ...data.map((x: ChartElement) => [x.name, x.value])
    ];
  }

  chartPalette = {
    red: "#dc3a12",
    blue: "#3366cc",
    green: "#109619",
    orange: "#ff9900",
    grey: "#888888"
  };

  buildColors(data: ChartElement[]): any {
    return data.map(el => {
      switch (el.name) {
        case "Live": return this.chartPalette.green;
        case "On Hold": return this.chartPalette.orange;
        case "Completed": return this.chartPalette.blue;
        case "Cancelled": return this.chartPalette.red;
        default: return this.chartPalette.grey;
      }
    });
  }

  buildCharts(): void {
    this.chartProducts = { ...this.chartProducts, dataTable: this.buildChart(this.data.productsByCategory), options: { ...this.pieOptions, colors: this.buildColors(this.data.productsByCategory) } };
    this.chartRecipes = { ...this.chartRecipes, dataTable: this.buildChart(this.data.recipesByCategory), options: { ...this.pieOptions, colors: this.buildColors(this.data.recipesByCategory) } };
    this.chartPackaging = { ...this.chartPackaging, dataTable: this.buildChart(this.data.packagingByCategory), options: { ...this.pieOptions, colors: this.buildColors(this.data.packagingByCategory) } };
    this.chartArtwork = { ...this.chartArtwork, dataTable: this.buildChart(this.data.artworksByCategory), options: { ...this.pieOptions, colors: this.buildColors(this.data.artworksByCategory) } };
  }

  pieOptions: any = {
    pieHole: 0.5,
    legend: { position: "labeled", alignment: "center" },
    chartArea: { left: 0, right: 0, top: 10, bottom: 10 },
    height: 250,
    pieSliceText: "none"
  };

  chartProducts: GoogleChartInterface = {
    chartType: GoogleChartType.PieChart,
    firstRowIsData: false,
    options: this.pieOptions
  };

  chartRecipes: GoogleChartInterface = {
    chartType: GoogleChartType.PieChart,
    firstRowIsData: false,
    options: this.pieOptions
  };

  chartPackaging: GoogleChartInterface = {
    chartType: GoogleChartType.PieChart,
    firstRowIsData: false,
    options: this.pieOptions
  };

  chartArtwork: GoogleChartInterface = {
    chartType: GoogleChartType.PieChart,
    firstRowIsData: false,
    options: this.pieOptions
  };

}
