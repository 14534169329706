import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PortalModule } from '@angular/cdk/portal';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ApolloInterceptor } from './apollo/ApolloInterceptor';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';

import { SharedModule } from './shared/shared.module';

// Shared/Standard Components
import { LoginPageComponent } from './components/login-page/login-page.component';
import { StandardLayoutComponent } from './components/standard-layout/standard-layout.component';
import { OrganisationComponent } from './components/organisation/organisation.component';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { OrganisationDashboardComponent } from './components/organisation-dashboard/organisation-dashboard.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { OrganisationSettingsComponent } from './components/organisation-settings/organisation-settings.component';
import { OrganisationRegistrationComponent } from './components/organisation-registration/organisation-registration.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { OrganisationUserListComponent } from './components/organisation-user-list/organisation-user-list.component';
import { InvitationCardComponent } from './components/invitation-card/invitation-card.component';
import { InvitationCreateDialogComponent } from './components/invitation-create-dialog/invitation-create-dialog.component';
import { OrganisationMemberEditDialogComponent } from './components/organisation-member-edit-dialog/organisation-member-edit-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    HomePageComponent,
    StandardLayoutComponent,
    OrganisationComponent,
    UserRegistrationComponent,
    UserProfileComponent,
    OrganisationDashboardComponent,
    ErrorPageComponent,
    OrganisationSettingsComponent,
    OrganisationRegistrationComponent,
    OrganisationUserListComponent,
    NotificationListComponent,
    InvitationCardComponent,
    InvitationCreateDialogComponent,
    OrganisationMemberEditDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    PortalModule,
    FlexLayoutModule,
    ApolloModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    FontAwesomeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApolloInterceptor, multi: true },
    ScreenTrackingService,
    UserTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
