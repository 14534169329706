<h2 mat-dialog-title>Upload Image</h2>

<mat-dialog-content>

    <div style="min-height: 400px;">

        <div>
            <input mat-raised-button color="primary" type="file" (change)="fileChangeEvent($event)" accept="image/png, image/jpeg" />
        </div>

        <div [style]="{ height: imageSize + 'px', margin: '8px 0 0 0' }">
            <img [src]="croppedImage" *ngIf="croppedImage" [style]="{ 'border-radius': '50%', height: imageSize + 'px', width: imageSize + 'px' }" />
        </div>

        <div>
            <image-cropper #avatarCropper style="width: 100%; height: 300px; border: solid 1px #cccccc; padding: 3px; margin: 12px 0;"
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="true"
                [resizeToWidth]="imageSize"
                [resizeToHeight]="imageSize"
                [aspectRatio]="1 / 1"
                [roundCropper]="true"
                format="jpeg"
                alignImage="center"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()">
            </image-cropper>
        </div>

    </div>

</mat-dialog-content>

<mat-dialog-actions>
    <app-action-group>
        <app-action action="create" label="SET IMAGE" [disabled]="!croppedImage" (execute)="onSubmit()"></app-action>
        <app-action action="delete" label="CLEAR IMAGE" (execute)="onClear()"></app-action>
        <app-action action="revert" label="CANCEL" mat-dialog-close></app-action>
    </app-action-group>
</mat-dialog-actions>
