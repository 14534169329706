import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetOrganisationGQL, Organisation, UserAccount } from 'src/generated/graphql';
import { Resolved } from '../models/resolved';
import { OrganisationService } from '../services/organisation.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationResolver implements Resolve<Resolved<Organisation>> {

  constructor(
    private router: Router,
    private getOrganisationGQL: GetOrganisationGQL,
    private organisationService: OrganisationService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Resolved<Organisation>> {

    if (!route.paramMap.has("shortcode")) {
      console.error("Parameter 'shortcode' missing within organisation resolver")
      this.organisationService.organisation = null;
      this.router.navigate(["/home"]);
      return of({ object: null, error: "Parameter 'shortcode' missing within organisation resolver" });
    }

    return this.getOrganisationGQL.fetch({ shortCode: route.paramMap.get("shortcode") }, { fetchPolicy: "no-cache" }).pipe(
      map(resp => {
        if (!resp.data.organisation) {
          this.organisationService.organisation = null;
          this.router.navigate(["/home"]);
          return { object: null, error: "Organisation data missing from GQL response" } as Resolved<Organisation>; 
        }
        this.organisationService.organisation = resp.data.organisation as Organisation;
        this.organisationService.users = resp.data.userAccounts as UserAccount[];
        return { object: resp.data.organisation } as Resolved<Organisation>; 
      }),
      catchError(error => {
        this.organisationService.organisation = null;
        this.router.navigate(["/home"]);
        return of({ object: null, error } as Resolved<Organisation>);
      })
    );
  }
}
