export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyC0RiQvS136y8PT81Rl8UzKoQPuj5Him-Q",
    authDomain: "sparke-club.firebaseapp.com",
    projectId: "sparke-club",
    storageBucket: "sparke-club.appspot.com",
    messagingSenderId: "337425720306",
    appId: "1:337425720306:web:b578a3c2f8f1099e17416b",
    measurementId: "G-PMZ7X4K1JF"
  }
};
