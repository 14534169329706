<div class="container max960">

    <app-item-header name="{{user.displayName}}" [thumbnail]="user.userAvatar96" category="User" subheading="{{user.emailAddress}}" [enableThumbnailUpload]="true" [thumbnailUploadSize]="96" (thumbnailChanged)="thumbnailChanged($event)"></app-item-header>

    <form [formGroup]="mainForm" style="margin-top: 16px">
        <div fxLayout="row wrap" fxLayoutGap="8px grid">
            <div fxFlex="50%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" formControlName="firstName">
                </mat-form-field>
            </div>
            <div fxFlex="50%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" formControlName="lastName">
                </mat-form-field>
            </div>
            <div fxFlex="50%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Display Name</mat-label>
                    <input matInput type="text" formControlName="displayName">
                </mat-form-field>
            </div>
            <div fxFlex="50%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input matInput type="text" formControlName="emailAddress">
                </mat-form-field>
            </div>
        </div>

        <div style="margin-bottom: 16px;">
            <h3>Communication Preferences</h3>
            <p>By ticking any of the options below, you are providing consent for us to contact you using the email address you have provided.</p>
            <div>
                <mat-checkbox formControlName="emailConsentMarketing">I wish to receive informational or marketing emails from Sparke Club</mat-checkbox>
            </div>
            <div>
                <mat-checkbox formControlName="emailConsentNotifications">I wish to receive system notifications (e.g. when I am assigned to a team or invitied to join an organisation)</mat-checkbox>
            </div>
            <div>
                <mat-checkbox formControlName="emailConsentTasks">I wish to receive task reminders (e.g. when a task needs to be performed on a product I manage)</mat-checkbox>
            </div>
        </div>

        <div *ngIf="!mainForm.pristine">
            <app-action-group>
                <app-action action="save" [disabled]="!mainForm.valid" (click)="saveProfile()"></app-action>
                <app-action action="revert" (click)="undoProfileChanges()"></app-action>
            </app-action-group>
        </div>
    </form>

    <mat-card style="margin-top: 16px" *ngFor="let invitation of invitations">
        <mat-card-header>
            <mat-card-title>Membership Invitation</mat-card-title>
            <mat-card-subtitle>Invitation to join {{invitation.organisation?.name}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div style="padding: 0 16px">
                <p>
                    You have been invited by {{invitation.createdBy?.displayName}} to join the organisation {{invitation.organisation?.name}}.
                </p>
                <p *ngIf="invitation.invitationMessage">
                    {{invitation.invitationMessage}}
                </p>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <app-action-group>
                <app-action action="create" label="ACCEPT INVITATION" (execute)="acceptInvitation(invitation)"></app-action>
                <app-action action="revert" label="REJECT INVITATION" (execute)="rejectInvitation(invitation)"></app-action>
            </app-action-group>
        </mat-card-actions>
    </mat-card>

</div>