import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AccessLevel, ChatMessage, Organisation, RoleLevel, UserAccount, Notification, GetNotificationsGQL, MarkNotificationReadGQL } from 'src/generated/graphql';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    _notifications: Notification[] = [];
    unreadCount = new BehaviorSubject<number>(0);

    constructor(
        private getNotificationsGQL: GetNotificationsGQL,
        private markNotificationReadGQL: MarkNotificationReadGQL
    ) {}

    loadNotifications() {
        this.getNotificationsGQL.fetch({}, { fetchPolicy: "no-cache" }).subscribe(resp => {
            this._notifications = [...resp.data.notifications as Notification[]];
            this.sendUnreadCount();
        })
    }

    onNotificationReceived(notification: Notification) {
        this._notifications = [notification, ...this._notifications.filter(x => x.id !== notification.id)];
        this.sendUnreadCount();
    }

    sendUnreadCount(): void {
        this.unreadCount.next(this._notifications.filter(x => !x.isRead).length);
    }

    markRead(notification: Notification) {
        let notif = this._notifications.find(x => x.id === notification.id);
        if (notif) {
            notif.isRead = true;
            this.markNotificationReadGQL.mutate({ notificationId: notif.id }).subscribe(resp => {
                if (!resp.errors && notif) {
                    this.sendUnreadCount();
                }
            })
        }
    }

    getNotifications(): Notification[] {
        return [...this._notifications.filter(x => !x.isRead)];
    }
}
