import { Injectable } from '@angular/core';
import { AccessLevel, Organisation, RoleLevel, UserAccount } from 'src/generated/graphql';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {

  private _organisation: Organisation | null = null;
  private _users: UserAccount[] = [];

  private _accessLevel: AccessLevel = AccessLevel.None;
  private _roleProduct: RoleLevel = RoleLevel.None;
  private _roleRecipe: RoleLevel = RoleLevel.None;
  private _rolePackaging: RoleLevel = RoleLevel.None;
  private _roleArtwork: RoleLevel = RoleLevel.None;

  constructor(
    private userService: UserService
  ) { 
  }

  get organisation(): Organisation | null {
    return this._organisation;
  }

  set organisation(org: Organisation | null) {
    this._organisation = org;

    this._accessLevel = AccessLevel.None;
    this._roleProduct = RoleLevel.None;
    this._roleRecipe = RoleLevel.None;
    this._rolePackaging = RoleLevel.None;
    this._roleArtwork = RoleLevel.None;

    if (org) {
      const user = this.userService.user;
      const membership = org.members?.find(x => x?.userAccountId === user?.id);
      if (membership) {
        this._accessLevel = membership.accessLevel;
        this._roleProduct = membership.productRole;
        this._roleRecipe = membership.recipeRole;
        this._rolePackaging = membership.packagingRole;
        this._roleArtwork = membership.artworkRole;
      }
    }
  }

  get users(): UserAccount[] {
    return this._users;
  }

  set users(userAccounts: UserAccount[] | null) {
    this._users = userAccounts || [];
  }

  get accessLevel() {
    return this._accessLevel;
  }

  get roleProduct() {
    return this._roleProduct;
  }

  get roleRecipe() {
    return this._roleRecipe;
  }

  get rolePackaging() {
    return this._rolePackaging;
  }

  get roleArtwork() {
    return this._roleArtwork;
  }
}
