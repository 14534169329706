<div class="container w1200">

    <!--
    <div style="text-align: left">
        <h1>{{organisation.name}}</h1>
        <p>
            {{organisation.description}}
        </p>
    </div>
    -->

    <div class="button-wrapper">
      <div class="button-row">
        <app-dashboard-button label="Create" icon="magic" description="Finished Products" routerLink="/org/{{shortCode}}/npd/products/items"></app-dashboard-button>
      </div>
      <div class="button-row">
        <app-dashboard-button label="Cook" icon="blender" description="Recipes" routerLink="/org/{{shortCode}}/npd/recipes/items"></app-dashboard-button>
        <app-dashboard-button label="Pack" icon="box-open" description="Product Packaging" routerLink="/org/{{shortCode}}/npd/packaging/items"></app-dashboard-button>
        <app-dashboard-button label="Design" icon="paint-brush" description="Artwork and Design" routerLink="/org/{{shortCode}}/npd/artwork/items"></app-dashboard-button>
      </div>
    </div>

    <h1 style="margin-top: 32px">Dashboard</h1>

    <div *ngIf="data" class="pie-charts">

        <div class="dashboard-tile mat-elevation-z2" *ngIf="data.productsByCategory">
            <h3>Products</h3>
            <div class="chart-wrapper">
                <google-chart [data]="chartProducts"></google-chart>
            </div>
            <div class="legend">{{buildCategoryLegend(data.productsByCategory)}}</div>
        </div>

        <div class="dashboard-tile mat-elevation-z2" *ngIf="data.recipesByCategory">
            <h3>Recipes</h3>
            <div class="chart-wrapper">
                <google-chart [data]="chartRecipes"></google-chart>
            </div>
            <div class="legend">{{buildCategoryLegend(data.recipesByCategory)}}</div>
        </div>

        <div class="dashboard-tile mat-elevation-z2" *ngIf="data.packagingByCategory">
            <h3>Packaging</h3>
            <div class="chart-wrapper">
                <google-chart [data]="chartPackaging"></google-chart>
            </div>
            <div class="legend">{{buildCategoryLegend(data.packagingByCategory)}}</div>
        </div>

        <div class="dashboard-tile mat-elevation-z2" *ngIf="data.artworksByCategory">
            <h3>Artwork</h3>
            <div class="chart-wrapper">
                <google-chart [data]="chartArtwork"></google-chart>
            </div>
            <div class="legend">{{buildCategoryLegend(data.artworksByCategory)}}</div>
        </div>

    </div>

</div>
