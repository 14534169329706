import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AccessLevel, ChatMessage, Organisation, RoleLevel, UserAccount } from 'src/generated/graphql';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

    messages: Subject<ChatMessage> = new Subject<ChatMessage>();

    onMessageReceived(chatMessage: ChatMessage) {
        this.messages.next(chatMessage);
    }
}
