<div class="item-header">
    <div class="headings">
        <div style="display: flex">
            <div *ngIf="thumbnail || enableThumbnailUpload">
                <img [src]="thumbnail || whitePixel" style="width: 56px; margin-right: 12px; border-radius: 50%;" (click)="uploadThumbnail()" [ngStyle]="{ cursor: enableThumbnailUpload ? 'pointer' : 'default', border: thumbnail ? '' : 'solid 1px #ddd' }">
            </div>
            <div style="flex: 1">
                <h2 style="margin-bottom: 0"><span *ngIf="category" style="font-weight: normal;">{{ category }} </span>{{name}}</h2> 
                <div *ngIf="subheading">{{subheading}}</div>
            </div>
        </div>
    </div>
    <div *ngIf="enableSettings" class="buttons">
        <button mat-icon-button (click)="onSettingsClicked()"><mat-icon>settings</mat-icon></button>
    </div>
    <div *ngIf="enableClose" class="buttons">
        <button *ngIf="closeLink" mat-icon-button [routerLink]="closeLink"><mat-icon>close</mat-icon></button>
        <button *ngIf="!closeLink" mat-icon-button (click)="onCloseClicked()"><mat-icon>close</mat-icon></button>
    </div>
</div>
