import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import extractFiles from 'extract-files/extractFiles.mjs';
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  console.log(`Configuring application for ${location.hostname}`);

  let uri = 'https://stevie-api.websimian.com/graphql/';
  let uri_ws = 'wss://stevie-api.websimian.com/graphql/';

  // Local Development Server Config
  if (location.hostname === "localhost") {
    uri = 'https://localhost:5001/graphql/';
    uri_ws = 'wss://localhost:5001/graphql/';
  }

  // Alpha Test Preview Server Config
  if (location.hostname.includes("web.app") && location.hostname.includes("alpha")) {
    uri = 'https://stevie-api.websimian.com/graphql/';
    uri_ws = 'wss://stevie-api.websimian.com/graphql/';
  }

  const http = httpLink.create({
    uri,
    useMultipart: true,
    extractFiles: (body) => extractFiles(body, isExtractableFile) });

  const ws = new WebSocketLink({
    uri: uri_ws,
    options: {
      timeout: 60000,
      reconnect: true,
    },
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
      );
    },
    ws,
    http
  );

  return {
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: { errorPolicy: 'all' },
      mutate: { errorPolicy: 'all' },
      query: { errorPolicy: 'all' }
    }
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
