import { TemplatePortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalBridgeService {

  private portalSidenav = new Subject<TemplatePortal>();
  readonly portalSidenav$ = this.portalSidenav.asObservable();

  private portalOrganisation = new Subject<TemplatePortal>();
  readonly portalorganisation$ = this.portalOrganisation.asObservable();

  constructor() { 
  }

  setPortalSidenav(portal: TemplatePortal) {
    this.portalSidenav.next(portal);
  }

  setPortalOrganisation(portal: TemplatePortal) {
    this.portalOrganisation.next(portal);
  }
}
