import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { GraphQLModule } from '../graphql/graphql.module';
import { FormsModule } from '@angular/forms';

// @angular/material
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatLuxonDateModule, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';

// Angular CDK
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgxCurrencyModule } from "@websimian/ngx-currency";
import { NgxBarcodeModule } from '@websimian/ngx-barcode';
import { AvatarModule } from 'ngx-avatars';
import { ImageCropperModule } from 'ngx-image-cropper';

import { UserNameComponent } from './components/user-name/user-name.component';
import { DebugPanelComponent } from './components/debug-panel/debug-panel.component';
import { ItemHeaderComponent } from './components/item-header/item-header.component';
import { RouterModule } from '@angular/router';
import { ItemFormActionsComponent } from './components/item-form-actions/item-form-actions.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { GridCellButtonComponent } from './components/grid-cell-button/grid-cell-button.component';
import { GridCellStatusComponent } from './components/grid-cell-status/grid-cell-status.component';
import { GridCellThumbnailComponent } from './components/grid-cell-thumbnail/grid-cell-thumbnail.component';
import { ActionComponent } from './components/action/action.component';
import { ActionOverlayComponent } from './components/action-overlay/action-overlay.component';
import { ActionGroupComponent } from './components/action-group/action-group.component';

import { ModuleRegistry } from '@ag-grid-community/core';     // @ag-grid-community/core will always be implicitly available
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridModule } from '@ag-grid-community/angular';
import { FontAwesomeModule, FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';
import { CdkAccordionModule } from '@angular/cdk/accordion';

// Others
import { NgSelectModule } from '@ng-select/ng-select';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import { faBlender, faBook, faBoxOpen, faChartPie, faCheckCircle, faComment, faHamburger, faHome, faMagic, faPaintBrush, faTachometerAlt, faTools, faUser, faComments, faImages, faCommentDots, faUsers, faUserTag, faScroll, faFile, faFileAlt, faAppleAlt, faBox, faCarrot, faPepperHot, faTree, faBoxes, faPencilRuler, faUserCircle, faHandshake, faBuilding, faStore, faThumbsDown, faClock, faThumbsUp, faUpload, faPeopleArrows, faKey, faQuestionCircle, faExclamationCircle, faSlidersH, faDatabase, faFilter, faTimes, faEye, faEdit, faQuestion, faUserSlash, faUserShield, faUserTie, faUserEdit, faArrowRight, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { faComments as farComments, faComment as farComment, faImages as farImages, faCommentDots as farCommentDots } from '@fortawesome/free-regular-svg-icons';
import { VersionSwitcherComponent } from './components/version-switcher/version-switcher.component';
import { AvatarUploadDialogComponent } from './components/avatar-upload-dialog/avatar-upload-dialog.component';
import { ImageViewDialogComponent } from './components/image-view-dialog/image-view-dialog.component';
import { RememberTabDirective } from './directives/remember-tab.directive';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { SitenavComponent } from './components/sitenav/sitenav.component';
import { SitenavGroupComponent } from './components/sitenav-group/sitenav-group.component';
import { SitenavItemComponent } from './components/sitenav-item/sitenav-item.component';
import { DashboardButtonComponent } from './components/dashboard-button/dashboard-button.component';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  InfiniteRowModelModule
]);

@NgModule({
  declarations: [
    UserNameComponent,
    DebugPanelComponent,
    ItemHeaderComponent,
    ItemFormActionsComponent,
    GridCellButtonComponent,
    GridCellStatusComponent,
    GridCellThumbnailComponent,
    VersionSwitcherComponent,
    AvatarUploadDialogComponent,
    ImageViewDialogComponent,
    RememberTabDirective,
    ActionComponent,
    ActionOverlayComponent,
    ActionGroupComponent,
    SitenavComponent,
    SitenavGroupComponent,
    SitenavItemComponent,
    DashboardButtonComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatIconModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatTooltipModule,
    MatDividerModule,
    MatChipsModule,
    MatDialogModule,
    MatRippleModule,
    DragDropModule,
    NgxBarcodeModule,
    NgxCurrencyModule,
    CdkAccordionModule,
    AvatarModule,
    AgGridModule,
    FontAwesomeModule,
    NgSelectModule,
    MtxSelectModule,
    ImageCropperModule,
    Ng2GoogleChartsModule
  ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTabsModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatBadgeModule,
        MatTableModule,
        MatSortModule,
        MatMenuModule,
        MatExpansionModule,
        MatSelectModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatLuxonDateModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        MatDividerModule,
        MatChipsModule,
        MatTooltipModule,
        DragDropModule,
        NgxBarcodeModule,
        NgxCurrencyModule,
        AvatarModule,
        GraphQLModule,
        AgGridModule,
        FontAwesomeModule,
        CdkAccordionModule,
        MatRippleModule,
        Ng2GoogleChartsModule,

        UserNameComponent,
        DebugPanelComponent,
        ItemHeaderComponent,
        ItemFormActionsComponent,
        NgSelectModule,
        MtxSelectModule,
        ImageCropperModule,
        VersionSwitcherComponent,
        ImageViewDialogComponent,
        RememberTabDirective,
        ActionComponent,
        ActionGroupComponent,
        ActionOverlayComponent,
        SitenavGroupComponent,
        DashboardButtonComponent
    ],
  providers: [
    {provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    // {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL'],
        },
        display: {
          dateInput: 'd MMM yyyy',
          monthYearLabel: 'MMM yyyy',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM yyyy',
        },
      },
    }
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {
    faConfig.fixedWidth = true;
    library.addIcons(
      faBook,
      faTachometerAlt,
      faHome,
      faUser,
      faUserCircle,
      faHamburger,
      faBlender,
      faBoxOpen,
      faPaintBrush,
      faChartPie,
      faMagic,
      faTools,
      faCheckCircle,
      faComments,
      faComment,
      faImages,
      faCommentDots,
      faUsers,
      faUserTag,
      faScroll,
      faFile,
      faFileAlt,
      faPepperHot,
      faBox,
      faBoxes,
      faTree,
      faPencilRuler,
      faHandshake,
      faBuilding,
      faStore,
      faClock,
      faThumbsUp,
      faThumbsDown,
      faUpload,
      faPeopleArrows,
      faKey,
      faQuestionCircle,
      faExclamationCircle,
      faSlidersH,
      faDatabase,
      faFilter,
      faTimes,
      faEye,
      faEdit,
      faQuestion,
      faUserSlash,
      faUserTie,
      faUserShield,
      faUserEdit,
      faArrowRight,
      faCaretRight,

      farComments,
      farComment,
      farImages,
      farCommentDots
    );
  }
}
