import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

    private ongoingRequests = 0;
    inFlight = new BehaviorSubject(0);

    beginNetworkRequest(): void {
        this.ongoingRequests++;
        this.inFlight.next(this.ongoingRequests);
    }

    endNetworkRequest(): void {
        this.ongoingRequests--;
        this.inFlight.next(this.ongoingRequests);
    }
}
