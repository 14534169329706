import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from 'src/app/services/organisation.service';
import { UserService } from 'src/app/services/user.service';
import { Organisation, UpdateOrganisationGQL, UserAccount } from 'src/generated/graphql';

@Component({
  selector: 'app-organisation-settings',
  templateUrl: './organisation-settings.component.html',
  styleUrls: ['./organisation-settings.component.css']
})
export class OrganisationSettingsComponent implements OnInit {
  
  organisation: Organisation;
  user: UserAccount;

  mainForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required, Validators.maxLength(128)]),
    description: new UntypedFormControl("", [Validators.required, Validators.maxLength(2000)]),
    shortCode: new UntypedFormControl("", [Validators.required, Validators.maxLength(8)]),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private updateOrganisationGQL: UpdateOrganisationGQL,
    private organisationService: OrganisationService
  ) { 
    this.organisation = route.snapshot.data.organisation.object as Organisation;
    this.user = userService.user as UserAccount;

    this.mainForm.patchValue({
      name: this.organisation.name,
      shortCode: this.organisation.shortCode,
      description: this.organisation.description
    });
  }

  ngOnInit(): void {
  }

  saveChanges(): void {
    this.updateOrganisationGQL.mutate({
      organisation: {
        organisationId: this.organisation.id,
        name: this.mainForm.get("name")?.value,
        description: this.mainForm.get("description")?.value,
        shortCode: this.mainForm.get("shortCode")?.value
      }
    }).subscribe(resp => {
      if (!resp.errors && resp.data?.updateOrganisation) {
        this.organisation = resp.data.updateOrganisation as Organisation;
        this.mainForm.markAsPristine();
        // Force a reload when the organisation is changed
        window.location.href = `/org/${this.organisation.shortCode}/npd/admin/settings`;
      }
    })
  }

  revertChanges(): void {
    this.mainForm.patchValue({
      name: this.organisation.name,
      shortCode: this.organisation.shortCode,
      description: this.organisation.description
    });
    this.mainForm.markAsPristine();
  }

}
