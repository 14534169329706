import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserInterfaceService {

  private sidebarCollapse: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  getSidebarCollapse(): Observable<boolean> {
    return this.sidebarCollapse.asObservable();
  }

  setSidebarCollapse(collapse: boolean): void {
    if (this.sidebarCollapse.value !== collapse) {
      this.sidebarCollapse.next(collapse);
    }
  }
}
