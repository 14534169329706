import { NgModule } from '@angular/core';
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { map } from 'rxjs/operators';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { OrganisationDashboardComponent } from './components/organisation-dashboard/organisation-dashboard.component';
import { OrganisationRegistrationComponent } from './components/organisation-registration/organisation-registration.component';
import { OrganisationSettingsComponent } from './components/organisation-settings/organisation-settings.component';
import { OrganisationComponent } from './components/organisation/organisation.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AllCategoriesResolver } from './resolvers/all-categories.resolver';
import { NotificationsResolver } from './resolvers/notifications.resolver';
import { OrganisationResolver } from './resolvers/organisation.resolver';
import { UserAccountResolver } from './resolvers/user-account.resolver';
import { UserInvitationsResolver } from './resolvers/user-invitations.resolver';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);
const redirectToHomeOrLogin = () => map(user => user ? ['home'] : ['login']);

const routes: Routes = [

  {
    path: 'login',
    ...canActivate(redirectLoggedInToHome),
    component: LoginPageComponent
  },

  {
    path: 'error',
    component: ErrorPageComponent
  },

  {
    path: '',
    ...canActivate(redirectUnauthorizedToLogin),
    resolve: { userAccount: UserAccountResolver },
    children: [

      {
        path: 'home',
        component: HomePageComponent,
      },
   
      {
        path: 'profile',
        component: UserProfileComponent,
        resolve: { invitations: UserInvitationsResolver }
      },

      {
        path: 'notifications',
        component: NotificationListComponent,
        resolve: { notifications: NotificationsResolver }
      },

      {
        path: 'register-org',
        component: OrganisationRegistrationComponent
      },

      {
        path: 'org/:shortcode',
        component: OrganisationComponent,
        resolve: { organisation: OrganisationResolver, categories: AllCategoriesResolver },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        children: [
          {
            path: '',
            redirectTo: 'dashboard',
            pathMatch: 'full'
          },
          { 
            path: 'dashboard', 
            component: OrganisationDashboardComponent,
            resolve: { organisation: OrganisationResolver },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange'   
          },
          { 
            path: 'settings', 
            component: OrganisationSettingsComponent
          },
          { 
            path: 'npd', 
            loadChildren: () => import('./npd/npd.module').then(m => m.NpdModule) 
          }
        ]
      },
    
      { 
        path: '**', 
        ...canActivate(redirectToHomeOrLogin),
        component: LoginPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: "always", enableTracing: false })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
