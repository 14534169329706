import {Component, Input, OnInit} from '@angular/core';
import {CdkAccordionItem} from "@angular/cdk/accordion";
import {
  EXPANSION_PANEL_ANIMATION_TIMING,
  matExpansionAnimations,
  MatExpansionPanelState
} from "@angular/material/expansion";
import {animate, state, style, transition, trigger} from "@angular/animations";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const animations = {
  indicatorRotate: trigger('indicatorRotate', [
    state('collapsed, void', style({transform: 'rotate(0deg)'})),
    state('expanded', style({transform: 'rotate(90deg)'})),
    transition('expanded <=> collapsed, void => collapsed',
      animate("225ms cubic-bezier(0.4,0.0,0.2,1)")),
  ])
};

@Component({
  selector: 'app-sitenav-group',
  templateUrl: './sitenav-group.component.html',
  styleUrls: ['./sitenav-group.component.css'],
  animations: [
    matExpansionAnimations.bodyExpansion,
    animations.indicatorRotate
  ]
})
export class SitenavGroupComponent extends CdkAccordionItem implements OnInit {

  @Input() icon: IconProp = "home";
  @Input() text: string = "";
  @Input() active: boolean = false;

  ngOnInit(): void {
  }

  getExpandedState(): MatExpansionPanelState {
    return this.expanded ? "expanded" : "collapsed";
  }

}
