import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AvatarUploadOptions } from 'src/app/models/avatar-upload-options';
import { Maybe } from 'src/generated/graphql';
import { AvatarUploadDialogComponent } from '../avatar-upload-dialog/avatar-upload-dialog.component';
import {UserInterfaceService} from "../../../services/user-interface.service";

@Component({
  selector: 'app-item-header',
  templateUrl: './item-header.component.html',
  styleUrls: ['./item-header.component.css']
})
export class ItemHeaderComponent implements OnInit, AfterViewInit {

  @Input() name!: string;
  @Input() category?: string;
  @Input() subheading?: string;
  @Input() closeLink?: string;
  @Input() enableClose?: boolean;
  @Input() enableSettings?: boolean;
  @Input() thumbnail?: Maybe<string>;
  @Input() enableThumbnailUpload = false;
  @Input() thumbnailUploadSize = 56;
  @Output() close = new EventEmitter<void>();
  @Output() settings = new EventEmitter<void>();
  @Output() thumbnailChanged = new EventEmitter<string>();

  whitePixel = "data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

  constructor(
    private dialog: MatDialog,
    private userInterfaceService: UserInterfaceService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.enableClose) {
      this.userInterfaceService.setSidebarCollapse(true);
    }
  }

  onCloseClicked(): void {
    this.close.emit();
  }

  onSettingsClicked(): void {
    this.settings.emit();
  }

  uploadThumbnail() {

    if (!this.enableThumbnailUpload) return;

    const dialogRef = this.dialog.open<AvatarUploadDialogComponent, AvatarUploadOptions>(AvatarUploadDialogComponent, { data: { size: this.thumbnailUploadSize }, width: "650px" });
    dialogRef.afterClosed().subscribe(imageData => {
      if (imageData) {
        if (imageData === "CLEAR") {
          this.thumbnail = null;
          this.thumbnailChanged.emit("");
        } else {
          this.thumbnail = imageData;
          this.thumbnailChanged.emit(imageData);
        }
      }
    });
  }

}
