<div *ngIf="loading" class="container" style="padding-top: 70px; text-align: center;">
    <div style="width: 50px; margin: 0 auto; padding-bottom: 12px;">
        <mat-spinner diameter="50"></mat-spinner>
    </div>
    <h2>Loading...</h2>
</div>

<div class="container" *ngIf="!loading">

    <div *ngIf="!user?.emailVerified" class="user-unverified">
        <p>
            Your email address has not been verified. To verify your address, please click on the Verify Account button, and follow the instructions in the email you will receive.
        </p>
        <div>
            <app-action action="create" label="VERIFY ACCOUNT" [disabled]="verificationSent" (execute)="verifyAccount()"></app-action>
        </div>
    </div>

    <div *ngIf="!organisations || organisations.length === 0" class="no-organisations">
        <h1>WELCOME TO STEVIE!</h1>
        <hr>        
        <h2><mat-icon style="font-size: 38px">group_add</mat-icon><br>Join an Organisation</h2>
        <p>
            You're not a member of any organisations at the moment - you'll need to join an organisation before you can use Stevie.
        </p>
        <p>
            If you wish to join an organisation that already uses Stevie, you'll need them to invite you to join them. Please provide your email address to a contact
            within the organisation and they will be able to invite you.
        </p>
        <p>
            Once you've been invited, check your <a href="/profile">user profile</a> to accept the invitation.
        </p>

        <div *ngIf="user?.maxOwnedOrganisations">
            <h2><mat-icon style="font-size: 38px">add_circle</mat-icon><br>Register Your Own Organisation</h2>
            <p>
                Alternatively, you can set up your own organisation. To do this, click on the 'Register Organisation' button below.
            </p>
            <p>
                <a mat-raised-button color="primary" [routerLink]="['/register-org']">REGISTER ORGANISATION</a>
            </p>
        </div>
    </div>

    <!--
    <div *ngIf="organisations && organisations.length > 0">
        <div *ngFor="let org of organisations">
            <mat-card class="org-card mat-elevation-z4">
                <mat-card-header>
                    <div mat-card-avatar>
                        <ngx-avatar size={{44}} name={{org.organisationName}} initialsSize="3" class="card-avatar"></ngx-avatar>
                    </div>
                    <mat-card-title>{{org.organisationName}}</mat-card-title>
                    <mat-card-subtitle>Organisation</mat-card-subtitle>
                </mat-card-header>
                <img mat-card-image src="/assets/img/card/card-header-organisation-2.png">
                <mat-card-content>
                    {{org.organisationDescription}}
                </mat-card-content>
                <mat-card-actions>
                    <a color="primary" mat-button [routerLink]="['/','org',org.shortCode,'dashboard']">DASHBOARD</a>
                    <a color="primary" mat-button [routerLink]="['/','org',org.shortCode,'npd','products']">PRODUCTS</a>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    -->
    
</div>
