import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrganisationMembership } from 'src/app/models/core-data.model';
import { UserService } from 'src/app/services/user.service';
import { UserAccount } from 'src/generated/graphql';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  user?: UserAccount;
  organisations?: OrganisationMembership[];
  coreData$?: Subscription;
  verificationSent = false;

  loading = true;

  constructor(
    private userService: UserService, 
    private snackBar: MatSnackBar,
    private router: Router
  ) { 
  }

  ngOnInit(): void {
    this.coreData$ = this.userService.getCoreData().subscribe(coreData => {
      if (coreData.authenticated) {
        this.user = coreData.user;
        this.organisations = coreData.organisations;
        this.loading = false;
        if (this.organisations?.length) {
          const mruOrg = localStorage.getItem("MRU_Org");
          if (mruOrg && this.organisations.find(x => x.shortCode === mruOrg)) {
            this.router.navigate(['org', mruOrg, 'dashboard']);
          } else {
            this.router.navigate(['org', this.organisations[0].shortCode, 'dashboard']);
          }
        }
      } else {
        this.user = undefined;
        this.organisations = undefined;
        this.loading = true;
      }
    });
  }

  ngOnDestroy() {
    this.coreData$?.unsubscribe();
  }

  verifyAccount() {
    this.userService.verifyAccount().subscribe(resp => {
      if (resp) {
        this.snackBar.open("Verification email has been sent");
        this.verificationSent = true;
      }
    })
  }

  registerOrganisation() {
    
  }
}
