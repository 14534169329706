<div class="container">

    <h1>Notifications</h1>

    <p *ngIf="notifications.length === 0">
        <mat-icon>done</mat-icon>
        You have no unread notifications
    </p>

    <mat-accordion>
        <mat-expansion-panel *ngFor="let notification of notifications; first as isFirst;" [expanded]="isFirst">
            <mat-expansion-panel-header>
                <mat-panel-title><mat-icon class="header-icon">notifications</mat-icon> {{notification.dateCreated | date:'dd MMM yyyy HH:mm:ss'}}</mat-panel-title>
                <mat-panel-description>
                    {{notification.subject}}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div>
                <pre>{{notification.text}}</pre>
                <div class="actions">
                    <app-action (execute)="markRead(notification)" label="DISMISS NOTIFICATION"></app-action>
                </div>
            </div>

        </mat-expansion-panel>
    </mat-accordion>

</div>