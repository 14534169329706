import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GetCurrentUserGQL, UserAccount } from 'src/generated/graphql';
import { Resolved } from '../models/resolved';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserAccountResolver implements Resolve<Resolved<UserAccount>> {

  constructor(
    private router: Router,
    private getCurrentUserGQL: GetCurrentUserGQL,
    private userService: UserService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Resolved<UserAccount>> {
    console.log("User Account Resolver executing");
    return this.getCurrentUserGQL.fetch(undefined, { fetchPolicy: "no-cache" }).pipe(
      map(resp => {
        if (!resp.data.currentUser) {
          console.log("User account resolver failed to obtain account details");
          this.userService.user = null;
          this.router.navigate(["/", "error"]);
          return { object: null, error: "User data missing from GQL response" } as Resolved<UserAccount>; 
        }
        this.userService.user = resp.data.currentUser as UserAccount;
        return { object: resp.data.currentUser } as Resolved<UserAccount>; 
      }),
      catchError(error => {
        console.log("User account resolver server error obtaining account details");
        this.userService.user = null;
        this.router.navigate(["/", "error"]);
        return of({ object: null, error } as Resolved<UserAccount>);
      })
    );
  }
}
