import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CoreDataGQL, GetCurrentUserGQL, UserAccount } from 'src/generated/graphql';
import { CoreData } from '../models/core-data.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private coreData$: BehaviorSubject<CoreData> = new BehaviorSubject<CoreData>({ initialised: false, authenticated: false });
  private avatar$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private user$: BehaviorSubject<UserAccount | null> = new BehaviorSubject<UserAccount | null>(null);

  public userAvatar = "";

  constructor(
    apollo: Apollo, 
    private auth: AngularFireAuth, 
    private coreDataGQL: CoreDataGQL,
    private currentUserGQL: GetCurrentUserGQL,
    private router: Router
  ) 
  {
    auth.onAuthStateChanged(user => {
      console.log(`Auth state changed - ${user ? user.displayName : 'No User'}`);
      if (!user) {
        apollo.client.resetStore();
        this.coreData$.next({ authenticated: false, initialised: true });
      } else {
        this.coreDataGQL.fetch().subscribe(resp => {
          if (!resp.data.currentUser) {
            // If no current user data, set authenticated to false
          this.coreData$.next({ authenticated: false, initialised: true });
          } else {
            // User data is available, set authenticated to true and populate user information
            this.coreData$.next({
              user: {
                id: resp.data.currentUser.id,
                emailAddress: resp.data.currentUser.emailAddress,
                displayName: resp.data.currentUser.displayName,
                firstName: resp.data.currentUser.firstName,
                lastName: resp.data.currentUser.lastName,
                disabled: resp.data.currentUser.disabled,
                emailVerified: resp.data.currentUser.emailVerified,
                dateCreated: resp.data.currentUser.dateCreated,
                maxOwnedOrganisations: resp.data.currentUser.maxOwnedOrganisations,
                isGlobalContact: resp.data.currentUser.isGlobalContact,
                emailConsentMarketing: resp.data.currentUser.emailConsentMarketing,
                emailConsentNotifications: resp.data.currentUser.emailConsentNotifications,
                emailConsentTasks: resp.data.currentUser.emailConsentTasks
              },
              organisations: resp.data.organisationMemberships?.map(x => ({
                accessLevel: x?.accessLevel || "None",
                organisationId: x?.organisation.id || "",
                organisationName: x?.organisation.name || "",
                organisationDescription: x?.organisation.description || "",
                shortCode: x?.organisation.shortCode || ""
              })) || [],
              authenticated: true,
              initialised: true
            });
          }
        });
      }
    }, error => {
      console.log(`Auth state change error: ${error}`);
      this.coreData$.next({ authenticated: false, initialised: true });
    });
  }

  getToken(): Observable<string | null> {
    return new Observable<string | null>(subscriber => this.auth.idToken.subscribe(token => {
      subscriber.next(token);
    }));
  }

  getCoreData(): Observable<CoreData> {
    return this.coreData$.asObservable();
  }

  getAvatar(): Observable<string> {
    return this.avatar$.asObservable();
  }

  getUserAccount(): Observable<UserAccount | null> {
    return this.user$.asObservable();
  }

  signInWithEmailAndPassword(emailAddress: string, password: string): Observable<firebase.default.User | null> {
    return new Observable(subscriber => {
      console.log("Signing in");
      this.auth.signInWithEmailAndPassword(emailAddress, password).then(x => {
        console.log(x.user);
        subscriber.next(x.user); 
        subscriber.complete();
      }).catch(reason => {
        console.log("Auth failed");
        console.log(reason);
        subscriber.next(null); 
        subscriber.complete();
      });
    });
  }
  
  signOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['login']);
    });
  }

  verifyAccount(): Observable<boolean> {
    return new Observable(subscriber => {
      this.auth.currentUser.then(user => {
        if (user) {
          user.sendEmailVerification();
          subscriber.next(true);
          subscriber.complete();
        }
      }, reason => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  private _user: UserAccount | null = null;

  refresh() {
    this.currentUserGQL.fetch({}, { fetchPolicy: "no-cache" }).subscribe(resp => {
      if (!resp.errors && resp.data.currentUser) {
        this.user = resp.data.currentUser as UserAccount;
        this.user$.next(resp.data.currentUser as UserAccount);
      }
    });
  }

  get user(): UserAccount | null {
    return this._user;
  }

  set user(userAccount: UserAccount | null) {
    this._user = userAccount;
    this.userAvatar = userAccount?.userAvatar96 || "";
    this.user$.next(this._user);
    this.avatar$.next(this.userAvatar);
  }

  setAvatar(imageData: string | null | undefined) {
    if (imageData || imageData === "") {
      this.userAvatar = imageData;
      this.avatar$.next(imageData);
    }
  }
}
