import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Category, GetAllCategoriesGQL } from 'src/generated/graphql';
import { Resolved } from '../models/resolved';
import { OrganisationService } from '../services/organisation.service';

@Injectable({
  providedIn: 'root'
})
export class AllCategoriesResolver implements Resolve<Resolved<Category[]>> {

  constructor(
    private router: Router,
    private getAllCategoriesGQL: GetAllCategoriesGQL
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Resolved<Category[]>> {

    if (!route.paramMap.has("shortcode")) {
      return of({ object: null, error: "No organisation available in organisation service" } as Resolved<Category[]>); 
    }

    const shortCode = route.paramMap.get("shortcode") || "";

    return this.getAllCategoriesGQL.fetch({ shortCode }, { fetchPolicy: "no-cache" }).pipe(
      map(resp => {
        if (!resp.data.allCategories) {
          this.router.navigate(["/home"]);
          return { object: null, error: "Category data missing from GQL response" } as Resolved<Category[]>; 
        }
        return { object: resp.data.allCategories } as Resolved<Category[]>; 
      }),
      catchError(error => {
        this.router.navigate(["/home"]);
        return of({ object: null, error } as Resolved<Category[]>);
      })
    );
  }
}
