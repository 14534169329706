import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.css']
})
export class ActionComponent implements OnInit {

  @Input() confirm = "";
  @Input() disabled = false;
  @Input() action: "save" | "revert" | "create" | "delete" | "cancel" | "edit" | "view" | "approve" | "reject" | "open" | "upload" | "copy" | "close" | "default" = "default";
  @Input() label = "";
  @Input() muted = false;
  @Input() icon = "";

  @Output() execute = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick(): void {
    if (this.confirm) {
      if (!confirm(this.confirm)) {
        return;
      }
    }
    this.execute.emit();
  }

  getLabel(): string {
    if (this.label) {
      return this.label.toUpperCase();
    }
    switch (this.action) {
      case "save":
        return "SAVE";
      case "revert":
        return "UNDO CHANGES";
      default:
        return this.action.toUpperCase();
    }
  }

  getColour(): string {
    switch (this.action) {
      case "delete":
      case "reject":
        return "warn";
      default:
        return "accent";
    }
  }

  getClass(): string {
    
    if (this.muted) {
      return "btn-muted";
    }

    switch (this.action) {
      case "delete":
      case "reject":
        return "btn-danger";
      case "revert":
      case "cancel":
        return "btn-muted";
      default:
        return "btn-flat";
    }
  }
}
