<div class="container">

    <app-item-header name="Settings" category="Organisation"></app-item-header>

    <form [formGroup]="mainForm" style="margin-top: 24px">

        <div fxLayout="row wrap" fxLayoutGap="8px grid">
            <div fxFlex="75%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Organisation Name</mat-label>
                    <input matInput type="text" formControlName="name">
                </mat-form-field>
            </div>
            <div fxFlex="25%" fxFlex.xs="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Short Code</mat-label>
                    <input matInput type="text" formControlName="shortCode">
                </mat-form-field>
            </div>
            <div fxFlex="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows="5" type="text" formControlName="description"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="mainForm.get('shortCode')?.value !== organisation.shortCode" class="shortcode-warning">
            Changing the short code will affect all URLs for this organisation, and will cause problems for anyone from this organisation who is currently using the site. 
            This should only be done when no-one else from this organisation is using the site.
        </div>

        <div *ngIf="!mainForm.pristine" style="margin-bottom: 12px">
            <app-action-group>
                <app-action action="save" [disabled]="!mainForm.valid" (execute)="saveChanges()"></app-action>
                <app-action action="revert" (execute)="revertChanges()"></app-action>   
            </app-action-group>
        </div>

    </form>
</div>